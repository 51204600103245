import api from '../config/api';

function findAll() {
    return api
        .get('/categories')
        .then(response => response.data['hydra:member'].sort((a, b) => (a.name > b.name) ? 1 : -1));
}

function find(id) {
    return api
        .get('/categories/' + id)
        .then(response => response.data);
}

function updateFromMercure(categories, category) {
    const filteredCategories = categories.filter(item => item.id !== category.id);
    return [...filteredCategories, category].sort((a, b) => (a.name > b.name) ? 1 : -1);

}

function deleteFromMercure(categories, id) {
    return categories.filter(item => parseInt(item.id) !== parseInt(id));
}

export default { 
    findAll,
    find,
    updateFromMercure,
    deleteFromMercure,
}