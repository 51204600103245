import React, { useEffect } from 'react'
import classNames from 'class-names'
import GDPRSection from './partials/GDPRSection'
import OverlayScrollbars from 'overlayscrollbars'

const GDPRSettings = ({ id, onToggle, onValidate, className, locale, content }) => {

    useEffect(() => {
        new OverlayScrollbars(document.querySelector('.gdpr-content-wrapper'), { sizeAutoCapable:false, className : "os-theme-dark"});
    }, []);

    const handleToggle = (id, value) => onToggle(id, value);

    const handleClickValidate = () => onValidate()

    return (
        <div className={ classNames('gdpr-settings ', className) }>
            <div className="gdpr-overlay"></div>
            <div className="gdpr-popin">
                <div className="gdpr-container">
                    <div className="gdpr-title">
                        { locale.gdpr_title }
                    </div>
                    <div className="gdpr-content-wrapper os-host-flexbox">
                        <div className="gdpr-content">
                            <div className="gdpr-description">{ locale.gdpr_description }</div>
                            {
                                content.map((props, index) => <GDPRSection onToggle={ handleToggle } key={ index } { ...props } locale={ locale } />)
                            }
                        </div>
                    </div>
                    <div className="gdpr-footer">
                        <div onClick={ handleClickValidate } className="gdpr-button gdpr-button-accept">
                            { locale.gdpr_validate }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default GDPRSettings;