import React from 'react'
import classNames from 'class-names'

const GDPRToggle = ({ id, onToggle, isActive, allowChange, locale, className }) => {

    const handleClick = () => onToggle(!isActive);

    const AlwaysActive = () => {
        return (
            <div className="gdpr-toggle validated">
                <div className="gdpr-status gdpr-toggle-status">
                    { locale.gdpr_always_active }
                </div>
            </div>
        );
    };

    return !allowChange ? <AlwaysActive /> : (
        <div onClick={ handleClick } className={ classNames('gdpr-toggle', className, { 'validated': isActive }, id) }>
            <div className={ classNames("gdpr-status gdpr-toggle-status", id) }>
                { isActive ? locale.gdpr_active : locale.gdpr_inactive }
            </div>
            <div className={ classNames("gdpr-toggle-track", id) }>
                <div className={ classNames("gdpr-toggle-cursor", id) }></div>
            </div>
        </div>
    );
}

export default GDPRToggle;