import PropTypes from "prop-types";
import React, { Fragment } from "react";
import FooterOne from "../wrappers/footer/FooterOne";
import HeaderSix from "../wrappers/header/HeaderSix";

const LayoutOne = ({ children, stick = "" }) => {
  return (
    <Fragment>
      <HeaderSix layout="container-fluid" stick={ stick }/>
      {children}
      <FooterOne
        backgroundColorClass="bg-gray"
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
      />
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string
};

export default LayoutOne;
