import Roles from "../../../config/Roles";
import { isDefined, isDefinedAndNotVoid } from "../../../helpers/utils";

export const updateContext = (products, setProducts, data, setData, currentUser, selectedCategory) => {
    let updatedProducts = products;
    const newData = data.map(entity => {
        updatedProducts = entity['@id'].includes('products') ? treatProduct(entity, updatedProducts, currentUser, selectedCategory) : 
                          entity['@id'].includes('prices') ? treatPrice(entity, updatedProducts, currentUser) :
                          treatStock(entity, updatedProducts);
        return {...entity, treated: true};
    });
    setProducts(updatedProducts);
    setData(newData.filter(d => !isDefined(d.treated)));
    return new Promise((resolve, reject) => resolve(false));
};

const treatProduct = (product, updatedProducts, currentUser, selectedCategory) => {
    return !isDefined(product.id) || !hasAccessToProduct(product, currentUser) ? [...updatedProducts].filter(p => p['@id'] !== product['@id']) : getUpdatedProducts(product, updatedProducts, currentUser, selectedCategory);
};

const hasAccessToProduct = (product, currentUser) => {
    const { available, userGroups } = product;
    if (Roles.hasAdminPrivileges(currentUser)) {
        return true;
    } else if (isDefined(product) && isDefinedAndNotVoid(userGroups)) {
        const group = userGroups.find(g => g.value === currentUser.roles);
        return available && isDefined(group);
    }
    return false;
};

const treatPrice = (price, updatedProducts, currentUser) => {
    const linkedProduct = updatedProducts.find(pdt => pdt['@id'] === price.product);
    if (isDefined(linkedProduct) && isDefinedAndNotVoid(linkedProduct.userGroups)) {
        const userGroup = linkedProduct.userGroups.find(g => g.value === currentUser.roles);
        const isPriceUpdated = isDefined(userGroup) && price.priceGroup.userGroup.find(ug => ug === userGroup['@id']) !== undefined;
        if (isPriceUpdated)
            return updatedProducts.map(p => p.id !== linkedProduct.id ? p : getProductWithNewPrice(linkedProduct, price));
    }
    return updatedProducts;
};

const treatStock = (stock, updatedProducts) => {
    const { product, variation, size } = getProductLinkedToStock(stock, updatedProducts);
    return  !isDefined(product) ? updatedProducts :
            updatedProducts.map(p => p.id !== product.id ? p : getProductWithNewStock(product, variation, size, stock));
};

const getUpdatedProducts = (newProduct, updatedProducts, currentUser, selectedCategory) => {
    const userGroup = newProduct.userGroups.find(g => g.value === currentUser.roles);
    if (isDefined(userGroup)) {
        const {prices, ...publicVariables } = newProduct;
        let suitedProduct = {...publicVariables};
        const newPrice = getNewPrice(prices, userGroup);
        if (isDefined(newPrice)) {
            if (isDefined(suitedProduct.categories) && !Array.isArray(suitedProduct.categories)) {
                const newCategories = Object.keys(suitedProduct.categories).map((key) => suitedProduct.categories[key]);
                suitedProduct = {...suitedProduct, price: newPrice, categories: newCategories};
            } else {
                suitedProduct = {...suitedProduct, price: newPrice};
            }
            const catIds = suitedProduct.categories.map(c => c.id);
            const index = updatedProducts.findIndex(o => o.id === suitedProduct.id);
            return  parseInt(selectedCategory) !== -1 && !catIds.includes(parseInt(selectedCategory)) ?
                        updatedProducts.filter(p => p.id !== suitedProduct.id) : 
                        index === -1 ? 
                            [...updatedProducts, suitedProduct] :
                            updatedProducts.map(o => o.id !== suitedProduct.id ? o : suitedProduct);
        }
    }
    return updatedProducts;
};

const getNewPrice = (prices, userGroup) => {
    const newPrice = prices.find(p => {
        const searchedUserGroup = p.priceGroup.userGroup.find(ug => {
            const ugId = isDefined(ug) && typeof ug === 'object' ? ug['@id'] : ug;
            return ugId === userGroup['@id'];
        }); 
        return isDefined(searchedUserGroup);
    })
    return isDefined(newPrice) ? newPrice.amount : undefined;
};

const getProductWithNewPrice = (product, price) => isDefined(price.id) ? {...product, price: price.amount} : product;

const getProductWithNewStock = (product, variation, size, stock) => {
    if (isDefined(size))
        return {
            ...product, 
            variations: product.variations.map(v => {
                return v['@id'] !== variation['@id'] ? v : 
                        {...variation, sizes: variation.sizes.map(s => s['@id'] !== size['@id'] ? s : {...size, stocks: s.stocks.map(st => st['@id'] === stock['@id'] ? stock : st)})}
            })
        };
    else
        return {...product, stocks: product.stocks.map(st => st['@id'] === stock['@id'] ? stock : st)};
};

const getProductLinkedToStock = (stock, products) => {
    const entities = products.map(pdt => isStockMatching(stock, pdt)).filter(obj => isDefined(obj));
    return entities.length > 0 ? entities[0] : {product: null, variation: null, size: null};

};

const isStockMatching = (stock, product) => {
    // if (isDefined(product.stock) && product.stock['@id'] === stock['@id'])
    if (isDefined(product.stocks) && product.stocks.find(s => s['@id'] === stock['@id']) !== undefined)
        return { product, variation: null, size: null }
    else if (isDefined(product.variations)) {
        const variation = product.variations.find(v => v.sizes.find(s => s.stocks.find(st => st['@id'] === stock['@id']) !== undefined));
        if (isDefined(variation)) {
            const size = variation.sizes.find(s => s.stocks.find(st => st['@id'] === stock['@id']) !== undefined);
            return {product, variation, size};
        }
    }
    return null;
};