import React, { useEffect, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import ContainerContext from '../../contexts/ContainerContext';
import DeliveryContext from '../../contexts/DeliveryContext';
import ProductsContext from '../../contexts/ProductsContext';
import HomeContext from '../../contexts/HomeContext';
import { isDefined, isDefinedAndNotVoid } from '../../helpers/utils';
import { ToastProvider } from "react-toast-notifications";
import AuthActions from '../../services/AuthActions';
import CatalogActions from '../../services/CatalogActions';
import ContainerActions from '../../services/ContainerActions';
import CategoryActions from '../../services/CategoryActions';
import HomepageActions from '../../services/HomepageActions';
import Mercure from '../../mercure/Mercure';
import PlatformActions from '../../services/PlatformActions';
import PlatformContext from '../../contexts/PlatformContext';

const DataProvider = ({ loadedPlatform, children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(AuthActions.isAuthenticated());
    const [currentUser, setCurrentUser] = useState(AuthActions.getCurrentUser());
    const [settings, setSettings] = useState({});
    const [country, setCountry] = useState("FR");
    const [cities, setCities] = useState([]);
    const [catalogs, setCatalogs] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [relaypoints, setRelaypoints] = useState([]);
    const [condition, setCondition] = useState(undefined);
    const [eventSource, setEventSource] = useState({});
    const [products, setProducts] = useState([]);
    const [navSearch, setNavSearch] = useState("");
    const [containers, setContainers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [packages, setPackages] = useState([]);
    const [tourings, setTourings] = useState([]);
    const [totalWeight, setTotalWeight] = useState(null);
    const [availableWeight, setAvailableWeight] = useState(null);
    const [homepage, setHomepage] = useState(null);
    const [homepageTest, setHomepageTest] = useState(null);
    const [platform, setPlatform] = useState(null);

    useEffect(() => {
        fetchPlatformAndGeolocation();
        fetchSettings();
        AuthActions.setErrorHandler(setCurrentUser, setIsAuthenticated);
        CatalogActions.findAll()
                      .then(response => setCatalogs(response));
        HomepageActions.findAll()
                        .then(response => {
                            setHomepage(response.find(h => h.selected));
                            setHomepageTest(response.find(h => h.onTest));
                        });
    }, []);

    useEffect(() => {
        setCurrentUser(AuthActions.getCurrentUser());
        fetchSettings();
    }, [isAuthenticated]);

    useEffect(() => {
        setSelectedCategory(-1);
        CategoryActions
            .findAll()
            .then(response => setCategories(response));
    }, [currentUser]);

    useEffect(() => {
        if (isDefinedAndNotVoid(catalogs) && isDefined(country)) {
            const catalog = catalogs.find(catalogOption => catalogOption.code === country && catalogOption.isActive);
            const selection = isDefined(catalog) ? catalog : catalogs.find(country => country.isDefault);
            setSelectedCatalog(selection);
        }
    }, [catalogs, country]);

    useEffect(() => {
        if (isDefined(selectedCatalog))
            setCountry(selectedCatalog.code);
    }, [selectedCatalog]);

    const fetchPlatformAndGeolocation = () => {
        if (!isDefined(loadedPlatform)) {
            PlatformActions
                .find()
                .then(response => {
                    setPlatform(response);
                    defineGeolocation(response);
                })
                .catch(error => error);
        } else {
            setPlatform(loadedPlatform);
            defineGeolocation(loadedPlatform);
        }
    };

    const defineGeolocation = platform => {
        const { account } = platform;
        if (isDefined(platform) && isDefined(account) && isDefined(account.hasCountryGeolocation) && account.hasCountryGeolocation) {
            AuthActions.getGeolocation()
                       .then(response => setCountry(response));
        }
    };

    const fetchSettings = async () => {
        const newSettings = await AuthActions.getUserSettings();
        const newContainers = await ContainerActions.findAvailable(newSettings);
        setSettings(newSettings);
        setContainers(newContainers);
    };

    return (
        <ToastProvider placement="top-right">
            <PlatformContext.Provider value={ {platform, setPlatform} }>
            <AuthContext.Provider value={ {isAuthenticated, setIsAuthenticated, currentUser, setCurrentUser, eventSource, setEventSource, country, setCountry, settings, setSettings, selectedCatalog, setSelectedCatalog, catalogs, setCatalogs, platform, setPlatform} }>
            <DeliveryContext.Provider value={ {cities, setCities, relaypoints, setRelaypoints, condition, setCondition, packages, setPackages, totalWeight, setTotalWeight, availableWeight, setAvailableWeight, tourings, setTourings} }>
            <ContainerContext.Provider value={{ containers, setContainers }}>
            <ProductsContext.Provider value={ {products, setProducts, categories, setCategories, selectedCategory, setSelectedCategory, navSearch, setNavSearch} }>
            <HomeContext.Provider value={{ homepage, setHomepage, homepageTest, setHomepageTest }}>
                <Mercure>
                    { children }
                </Mercure>
            </HomeContext.Provider>
            </ProductsContext.Provider>
            </ContainerContext.Provider>
            </DeliveryContext.Provider>
            </AuthContext.Provider>
            </PlatformContext.Provider>
        </ToastProvider>
    );
}
 
export default DataProvider;