import React from 'react'
import classNames from 'class-names'
import GDPRToggle from './GDPRToggle'

const GDPRSection = ({ onToggle, id, className, title, description, allowChange, isAllowed, locale }) => {

    const handleToggle = (value) => onToggle(id, value);
  
    return (
        <div className={ classNames('gdpr-section', className) }>
            <div className="gdpr-title gdpr-section-title">{ title }</div>
            <div className="gdpr-description gdpr-section-description">{ description }</div>
            <GDPRToggle id={ id } onToggle={ handleToggle } allowChange={ allowChange } isActive={ isAllowed } locale={ locale } />
        </div>
    )
}

export default GDPRSection;