import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { multilanguage } from "redux-multilanguage";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import Roles from "../../config/Roles";
import AuthContext from "../../contexts/AuthContext";
import GDPRSettingsPanel from "../../pages/gdpr/GDPRSettingsPanel";

const FooterOne = ({ backgroundColorClass, spaceTopClass, spaceBottomClass, spaceLeftClass, spaceRightClass, containerClass, extraFooterClass, sideMenu, strings }) => {

  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className={`footer-area ${backgroundColorClass ? backgroundColorClass : ""} ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""} ${extraFooterClass ? extraFooterClass : ""}  ${spaceRightClass ? spaceRightClass : ""} d-flex justify-content-center `} style={{ width: '100%'}}>   {/* ${spaceLeftClass ? spaceLeftClass : ""} */}
      <div className={`${containerClass ? containerClass : "container "}`} style={{ width: '100%', marginLeft: 0, marginRight: 0}}>
        <div className="row d-flex justify-content-between" style={{ width: '100%'}}>
          <div className={`${ sideMenu ? "col-xl-3 col-sm-6" : "col-lg-3 col-sm-6" }`}>
            <FooterCopyright footerLogo="/assets/img/logo/logo_fp_1.png" spaceBottomClass="mb-30" />
          </div>
          <div className={`${sideMenu ? "col-xl-3 col-sm-6" : "col-lg-3 col-sm-6"}`}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{strings["navigation"].toUpperCase()}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li><Link to={process.env.PUBLIC_URL + "/about"}>{strings["about_us"]}</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/shop"}>{strings["shop"]}</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/contact"}>{strings["contact_us"]}</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/articles"}>{strings["blog"]}</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${sideMenu ? "col-xl-3 col-sm-6" : "col-lg-3 col-sm-6"}`}>
            <div className={`${sideMenu ? "footer-widget mb-30 ml-95" : "footer-widget mb-30 ml-50"}`}>
              <div className="footer-title">
                <h3>{strings["useful_links"].toUpperCase()}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li><GDPRSettingsPanel isFooter={ true }/></li>
                  <li><Link to={process.env.PUBLIC_URL + "/legal-notices"}>{strings["legal_notice"]}</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/terms-of-sales"}>{strings["terms_of_sales"]}</Link></li>
                  { Roles.hasPrivileges(currentUser) && 
                    <li><Link to={process.env.PUBLIC_URL + "/admin/home"}>Prévisualisation</Link></li>
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className={`${sideMenu ? "col-xl-3 col-sm-6" : "col-lg-3 col-sm-6"}`}>
            <FooterNewsletter spaceBottomClass="mb-30" spaceLeftClass="ml-70" sideMenu={sideMenu} />
          </div>
        </div>
      </div>
      <button className={`scroll-top ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()}>
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default multilanguage(FooterOne);
