import PropTypes from "prop-types";
import React, { useEffect, Suspense } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { Switch, Route, HashRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import routes from './routes';
import DataProvider from "./data/dataProvider/dataProvider";
import 'mapbox-gl/dist/mapbox-gl.css';

import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';

import en from "./translations/english.json";
import fn from "./translations/french.json";
import de from "./translations/germany.json";
import GDPRSettingsPanel from "./pages/gdpr/GDPRSettingsPanel";
import { isDefined } from "./helpers/utils";
import NotFound from "./pages/other/NotFound";
import AdminRoute from "./components/route/AdminRoute";

const loading = (
    <div className="flone-preloader-wrapper">
        <div className="flone-preloader">
            <span></span>
            <span></span>
        </div>
    </div>
);

const HomeTestWrapper = React.lazy(() => import("./pages/home/HomeTestWrapper"));

const App = ({platform, ...props}) => {

    useEffect(() => {
        props.dispatch( loadLanguages({languages: { en, fn, de }}) );
    }, []);

    return (
        <DataProvider loadedPlatform={ platform }>
            <ToastProvider placement="bottom-left">
                    <BreadcrumbsProvider>
                        <HashRouter>
                            <ScrollToTop>
                                <Suspense fallback={ loading }>
                                    <Switch>
                                        { routes.map((route, index) => {
                                            return route.component && (
                                                <Route
                                                    key={ index }
                                                    path={ route.path }
                                                    exact={ route.exact }
                                                    name={ route.name }
                                                    render={ props => <route.component {...props} />}
                                                />
                                            )
                                        })
                                        }
                                        <AdminRoute path="/admin/home" name="HomepageTest" component={ HomeTestWrapper } exact/>
                                        <Route component={ NotFound } />
                                    </Switch>
                                </Suspense>
                            </ScrollToTop>
                        </HashRouter>
                    </BreadcrumbsProvider>
            </ToastProvider>
            { (!isDefined(Cookies.get("gdpr-analytics")) && !isDefined(Cookies.get("gdpr-fbLogin"))) && 
                <CookieConsent
                    debug={ false }
                    location="bottom"
                    buttonText={ isDefined(props) && isDefined(props.strings) && props.strings["accept"] }
                    cookieName="gdpr-all"
                    cookieValue={ 1 }
                    declineCookieValue={ 0 }
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    enableDeclineButton
                    declineButtonText={ isDefined(props) && isDefined(props.strings) && props.strings["refuse"] }
                >
                    <i className="fas fa-cookie mr-2 text-warning"></i>{ isDefined(props) && isDefined(props.strings) && props.strings["cookie_warning"] }{" "}
                    <span style={{ fontSize: "10px", marginLeft: '15px'}}><GDPRSettingsPanel /></span>
                </CookieConsent>
            }
        </DataProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
