import React from 'react';

const GDPRPanel = React.lazy(() => import("./pages/gdpr/GDPRSettingsPanel"));

const HomeWrapper = React.lazy(() => import("./pages/home/HomeWrapper"));

const ShopGridPersonalized = React.lazy(() => import("./pages/shop/ShopGridPersonalized"));

const Product = React.lazy(() => import("./pages/shop-product/Product"));

const Articles = React.lazy(() => import("./pages/blog/BlogNoSidebar"));
const Article = React.lazy(() => import("./pages/blog/BlogDetailsStandard"));

const About = React.lazy(() => import("./pages/other/About"));
const Contact = React.lazy(() => import("./pages/other/Contact"));
const MyAccount = React.lazy(() => import("./pages/other/MyAccount"));
const ResetPassword = React.lazy(() => import("./pages/other/ResetPassword"));
const MyOrders = React.lazy(() => import("./pages/other/MyOrders"));
const MyTouring = React.lazy(() => import("./pages/other/MapVisualization"));
const DeliveryNote = React.lazy(() => import("./pages/other/DeliveryNote"));
const LoginRegister = React.lazy(() => import("./pages/other/LoginRegister"));

const Cart = React.lazy(() => import("./pages/other/Cart"));
const Wishlist = React.lazy(() => import("./pages/other/Wishlist"));
const Compare = React.lazy(() => import("./pages/other/Compare"));
const Checkout = React.lazy(() => import("./pages/other/Checkout"));
const LegalNotice = React.lazy(() => import("./pages/other/LegalNotice"));
const TermsOfUse = React.lazy(() => import("./pages/other/TermsOfUse"));
const Unsigned = React.lazy(() => import("./pages/other/Signature"));


const NotFound = React.lazy(() => import("./pages/other/NotFound"));

const routes = [
    { path: process.env.PUBLIC_URL + "/", exact: true, name: 'Home', component: HomeWrapper },
    { path: process.env.PUBLIC_URL + "/shop", exact: true, name: 'Shop', component: ShopGridPersonalized },
    { path: process.env.PUBLIC_URL + "/gdpr", exact: true, name: 'GDPRPanel', component: GDPRPanel },
    { path: process.env.PUBLIC_URL + "/product/:id", name: 'Product', component: Product },
    { path: process.env.PUBLIC_URL + "/articles/:id", name: 'Article', component: Article },
    { path: process.env.PUBLIC_URL + "/articles", name: 'Articles', component: Articles },
    { path: process.env.PUBLIC_URL + "/about", name: 'About', component: About },
    { path: process.env.PUBLIC_URL + "/contact", name: 'Contact', component: Contact },
    { path: process.env.PUBLIC_URL + "/my-account", name: 'MyAccount', component: MyAccount },
    { path: process.env.PUBLIC_URL + "/reset-password/:id", name: 'ResetPassword', component: ResetPassword },
    { path: process.env.PUBLIC_URL + "/my-orders", name: 'MyOrders', component: MyOrders },
    { path: process.env.PUBLIC_URL + "/my-touring/:id", name: 'MyTouring', component: MyTouring },
    { path: process.env.PUBLIC_URL + "/delivery-note/:id", name: 'DeliveryNote', component: DeliveryNote },
    { path: process.env.PUBLIC_URL + "/login-register", name: 'LoginRegister', component: LoginRegister },
    { path: process.env.PUBLIC_URL + "/cart", name: 'Cart', component: Cart },
    { path: process.env.PUBLIC_URL + "/wishlist", name: 'Wishlist', component: Wishlist },
    { path: process.env.PUBLIC_URL + "/compare", name: 'Compare', component: Compare },
    { path: process.env.PUBLIC_URL + "/checkout", name: 'Checkout', component: Checkout },
    { path: process.env.PUBLIC_URL + "/legal-notices", name: 'LegalNotice', component: LegalNotice },
    { path: process.env.PUBLIC_URL + "/terms-of-sales", name: 'TermsOfUse', component: TermsOfUse },
    { path: process.env.PUBLIC_URL + "/unsigned/:id", name: 'Unsigned', component: Unsigned },
    { path: process.env.PUBLIC_URL + "/not-found", name: 'NotFound', component: NotFound },
];

export default routes;