import { isDefined } from "../../../helpers/utils";

export const updateHomepage = (homepage, setHomepage, homepageTest, setHomepageTest, data, setData) => {

    const newData = data.map(entity => {
        const newHomepage = entity['@id'].includes('homepages') ? entity : entity.homepage;
        if (newHomepage.selected)
            treatSelection(entity, newHomepage, homepage, setHomepage)
        if (newHomepage.onTest)
            treatSelection(entity, newHomepage, homepageTest, setHomepageTest)
        return {...entity, treated: true};
    });
    setData(newData.filter(d => !isDefined(d.treated)));

    return new Promise((resolve, reject) => resolve(false));
};

const treatSelection = (entity, newHomepage, selection, setSelection) => {
    let updatedHomepage = newHomepage;
    if (entity['@id'].includes('banners') && newHomepage.id === selection.id) {
        const bannerIndex = selection.banners.findIndex(b => b['@id'] === entity['@id']);
        updatedHomepage = !isDefined(entity.id) ? {...selection, banners: selection.banners.filter(b => b['@id'] !== entity['@id'])} :
                            bannerIndex !== -1 ? {...selection, banners: selection.banners.map(b => b['@id'] !== entity['@id'] ? b : entity)} : [...selection.banners, entity];
    } else if (entity['@id'].includes('heroes') && newHomepage.id === selection.id) {
        const heroIndex = selection.heroes.findIndex(h => h['@id'] === entity['@id']);
        updatedHomepage = !isDefined(entity.id) ? {...selection, banners: selection.banners.filter(b => b['@id'] !== entity['@id'])} :
                          heroIndex !== -1 ? {...selection, heroes: selection.heroes.map(h => h['@id'] !== entity['@id'] ? h : entity)} : [...selection.heroes, entity];
    } else if (entity['@id'].includes('countdowns') && newHomepage.id === selection.id) {
        const countdownIndex = selection.countdowns.findIndex(b => b['@id'] === entity['@id']);
        updatedHomepage = !isDefined(entity.id) ? {...selection, countdowns: selection.countdowns.filter(c => c['@id'] !== entity['@id'])} :
                          countdownIndex !== -1 ? {...selection, countdowns: selection.countdowns.map(c => c['@id'] !== entity['@id'] ? c : entity)} : [...selection.countdowns, entity];
    }
    setSelection(updatedHomepage);
}

// if (entity['@id'].includes('banners') && newHomepage.id === homepage.id) {
//     const bannerIndex = homepage.banners.findIndex(b => b['@id'] === entity['@id']);
//     updatedHomepage = !isDefined(entity.id) ? {...homepage, banners: homepage.banners.filter(b => b['@id'] !== entity['@id'])} :
//                         bannerIndex !== -1 ? {...homepage, banners: homepage.banners.map(b => b['@id'] !== entity['@id'] ? b : entity)} : [...homepage.banners, entity];
// } else if (entity['@id'].includes('heroes') && newHomepage.id === homepage.id) {
//     const heroIndex = homepage.heroes.findIndex(h => h['@id'] === entity['@id']);
//     updatedHomepage = !isDefined(entity.id) ? {...homepage, banners: homepage.banners.filter(b => b['@id'] !== entity['@id'])} :
//                       heroIndex !== -1 ? {...homepage, heroes: homepage.heroes.map(h => h['@id'] !== entity['@id'] ? h : entity)} : [...homepage.heroes, entity];
// } else if (entity['@id'].includes('countdowns') && newHomepage.id === homepage.id) {
//     const countdownIndex = homepage.countdowns.findIndex(b => b['@id'] === entity['@id']);
//     updatedHomepage = !isDefined(entity.id) ? {...homepage, countdowns: homepage.countdowns.filter(c => c['@id'] !== entity['@id'])} :
//                       countdownIndex !== -1 ? {...homepage, countdowns: homepage.countdowns.map(c => c['@id'] !== entity['@id'] ? c : entity)} : [...homepage.countdowns, entity];
// }
// setHomepage(updatedHomepage);