import api from "../config/api";
import { isDefined } from "../helpers/utils";

// function findAll() {
//     return api
//         .get('/containers')
//         .then(response => response.data['hydra:member'].filter(container => container.available).sort((a, b) => (a.max < b.max) ? -1 : 1));
// }

function findAll() {
    return api
        .get('/containers')
        .then(response => response.data['hydra:member']);
}

function deleteContainer(id) {
    return api.delete('/containers/' + id);
}

function find(id) {
    return api
        .get('/containers/' + id)
        .then(response => response.data);
}

function findAvailable(userGroup) {
    const group = isDefined(userGroup) ? `group[]=${ userGroup.value }&` : '';
    return api
        .get(`/containers?available=true&${ group }order[name]=asc`)
        .then(response => response.data['hydra:member']);
}

function update(id, container) {
    return api.put('/containers/' + id, {...container});
}

function create(container) {
    return api.post('/containers', {...container});
}

export default {
    findAll,
    findAvailable,
    delete: deleteContainer,
    find,
    update,
    create
}